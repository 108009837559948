<template>
    <b-overlay :show="is_busy" rounded="sm">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-8">
                    <div class="mb-2 text-center">
                        <h2><strong>View Result</strong></h2>
                    </div>
                    <div class="card">
                        <div class="card-body">
                            <b-form @submit.prevent="updateUser()">
                                <b-form-group class="">
                                    <label>Select Session</label>
                                    <select v-model="form.session" class="form-control" required>
                                        <option value=null> -- Select Session-- </option>
                                        <option v-for="option in sessions" :key="option.id" v-bind:value="option.id">
                                            {{ option.name }}
                                        </option>
                                    </select>
                                </b-form-group>
                            
                                <b-form-group class="">
                                    <label>Select Term</label>
                                    <b-form-select v-model="form.term" :options="terms"></b-form-select>
                                </b-form-group>

                                <b-form-group class="" v-if="form.term!=4">
                                    <label>Result Type</label>
                                    <b-form-select v-model="form.type" :options="types"></b-form-select>
                                </b-form-group>

                                <b-form-group v-if="form.type==1 || form.term==4">
                                    <label>Select Student</label>
                                    <v-select label="bg" :options="students" @input="setSelected"></v-select>
                                </b-form-group>

                                <b-form-group v-if="form.type==2">
                                    <label>Select Class</label>
                                    <v-select label="title" :options="classes" @input="setClass"></v-select>
                                </b-form-group>
                                <button type="submit" class="btn btn-success btn-lg btn-block">Submit</button>
                            </b-form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-overlay>
</template>

<script>
    import axios from 'axios';
    import { Form } from 'vform';

    export default {
    
        data() {
            return {
                form: new Form({
                    student: '',
                    session: null,
                    term: null,
                    type:null,
                    class:null,
                }),
                sessions : [],
                students : [],
                classes : [],

                terms: [{ text: 'Select Term', value: null }, { text: 'First Term', value: 1 }, { text: 'Second Term', value: 2 }, { text: 'Third Term', value: 3 }, { text: 'Annual', value: 4 }],
                types: [{ text: 'Select Result Type', value: null }, { text: 'Single Student', value: 1 }, { text: 'All Student', value: 2 }],
                school: '',
                
                is_busy: false,
            }
        },

        created() {
            this.loadSettings();
        },

        methods: {
            setSelected(value) {
                this.form.student = value.id;
            },
            setClass(value) {
                this.form.class = value.id;
            },

            loadSettings(){
                if(this.is_busy) return;
                this.is_busy = true;

                axios.get('/loaddata') 
                .then((response)=>{ 
                this.sessions = response.data.data.sessions;
                this.classes = response.data.data.classes;
                this.school = response.data.data.school;
                this.subjects = response.data.data.subjects;
                this.students = response.data.data.students;
                })
                .catch((err)=>{
                Swal.fire(
                    "Error!",
                    err.response.data.data.error,
                    "error"
                );
                this.$router.back()
                })
                .finally(() => {
                this.is_busy = false;
            });
            },

            updateUser(){
                if(this.form.type==1 || this.form.type==null) {
                    if(!this.form.student || !this.form.session || !this.form.term){
                        Swal.fire(
                            "Failed!",
                            "Please select all the requirements.",
                            "warning"
                        );
                    }
                    else
                    {
                        if(this.form.term==4) {
                            this.$router.push({path: '/result/view/annual/'+ this.form.student + '/' + this.form.session + '/' + this.form.term });
                        }
                        else {
                            this.$router.push({path: '/result/view/'+ this.form.student + '/' + this.form.session + '/' + this.form.term });
                        }
                    } 
                }
                else {
                    if(!this.form.class || !this.form.session || !this.form.term){
                        Swal.fire(
                            "Failed!",
                            "Please select all the requirements.",
                            "warning"
                        );
                    }
                    else
                    {
                        if(this.form.term==4) {
                            this.$router.push({path: '/result/view/annual/'+ '/' + this.form.session + '/' + this.form.term });
                        }
                        else {
                            this.$router.push({path: '/result/view/all/'+ this.form.class + '/' + this.form.session + '/' + this.form.term });
                        }
                    } 
                }
            },
        }, 
    }
</script>
